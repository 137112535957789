import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
		path: '',
		redirectTo: 'tabs',
		pathMatch: 'full',
	},
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'view-alert',
    loadChildren: () => import('./models/view-alert/view-alert.module').then( m => m.ViewAlertPageModule)
  },
  {
    path: 'emergency-modal',
    loadChildren: () => import('./models/emergency-modal/emergency-modal.module').then( m => m.EmergencyModalPageModule)
  },
  {
    path: 'add-emergency',
    loadChildren: () => import('./add-emergency/add-emergency.module').then( m => m.AddEmergencyPageModule)
  },
  {
    path: 'input-modal',
    loadChildren: () => import('./models/input-modal/input-modal.module').then( m => m.InputModalPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'setting-location',
    loadChildren: () => import('./setting-location/setting-location.module').then( m => m.SettingLocationPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'forgot-username',
    loadChildren: () => import('./models/forgot-username/forgot-username.module').then( m => m.ForgotUsernamePageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./models/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'check-in-modal',
    loadChildren: () => import('./models/check-in-modal/check-in-modal.module').then( m => m.CheckInModalPageModule)
  },
  {
    path: 'act-create-modal',
    loadChildren: () => import('./models/act-create-modal/act-create-modal.module').then( m => m.ActCreateModalPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./models/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'change-location',
    loadChildren: () => import('./change-location/change-location.module').then( m => m.ChangeLocationPageModule)
  },
  {
    path: 'add-alert',
    loadChildren: () => import('./add-alert/add-alert.module').then( m => m.AddAlertPageModule)
  },
  // {
  //   path: 'communication-list',
  //   loadChildren: () => import('./communication-list/communication-list.module').then( m => m.CommunicationListPageModule)
  // },
  // {
  //   path: 'communication-list',
  //   loadChildren: () => import('./communication-list/communication-list.module').then( m => m.CommunicationListPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
