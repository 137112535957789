/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from './services/authentication-service.service';
import { EventService } from './services/event.service';
import { ApplicationEvents } from './infrastructure/events/application-events';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private eventService: EventService, private storage: Storage, private authenticationService: AuthenticationService) { }

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();

    // const token = await this.storage.get('accessToken');
    // if(token){
    //   console.log(token);
    //   AuthenticationService.authenticationToken = token;
    //   ApiClientService.authenticationToken = token;
    // }
    // else{
    //   console.log('no token found');
    // }
    //Notification settings
    if (Capacitor.platform !== 'web') {
      this.initNotificationSetup();
    }
    this.eventService.getObservable().subscribe((data => {
      console.log(data.eventName);
      // eslint-disable-next-line eqeqeq
      if (data.eventName == ApplicationEvents.onUnauthorizedEvent) {
        this.authenticationService.logout();
      }
      else if (data.eventName == AuthenticationService.authenticationSuccessEvent) {
        console.log('authentication success event fired');
        this.registerRequestForFCM();
      }
    }));

  }


  registerRequestForFCM(){
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
  }
  async processToken(fcmToken) {
    const savedfcmToken = await this.storage.get('fcmToken');
    console.log("Fcm token processing", fcmToken);
    //no saved token
    if (!savedfcmToken) {
      await this.storage.set('fcmToken', fcmToken.value);
      this.registerFcmTokenOnServer(fcmToken.value);
    }
    else {
      if (savedfcmToken != fcmToken.value) {
        //new token
        //save locally
        await this.storage.set('fcmToken', fcmToken.value);
        //save remote
        this.registerFcmTokenOnServer(fcmToken.value);
      }
      else {
        //same token do nothing
        //just for testing
        await this.storage.set('fcmToken', fcmToken.value);
        //save remote
        this.registerFcmTokenOnServer(fcmToken.value);
      }
    }
  }

  registerFcmTokenOnServer(value: string) {
    this.authenticationService.registerFcmToken(value).subscribe((data) => {
      console.log(data);
    }, error => {
      console.log('Couldnot register fcm token', error);
    });
  }



  initNotificationSetup() {
    console.log('Initializing Ffirebase Cloud messaging service');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
       // this.registerRequestForFCM();
      } else {
        // Show some error
      }
    });
    PushNotifications.addListener('registration', async (fcmToken: Token) => {
      console.log('Push registration success token found:');
      this.processToken(fcmToken);
    });
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Notification received: ' + JSON.stringify(notification));
      },
    );
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }






}
